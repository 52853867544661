<template>
  <div>
    <Component
      :is="getType(component)"
      v-for="(component, index) in components"
      :key="index"
      v-editor-target="index"
      :data="component"
      :scroll-to-index="index"
      :index="index"
      :prev-component="components[index - 1]?.type"
      :next-component="components[index + 1]?.type"
    />
  </div>
</template>

<script setup lang="ts">
import type { BlueprintPageFragment } from '#gql'
import type { Enforce } from '~/types/general'

defineProps<{
  components: Enforce<BlueprintPageFragment>['replicator_components']
}>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getType(component: any) {
  if (!component.type) {
    console.error(
      'ComponentLoader: Could not find component with type of',
      component
    )
    return null
  }

  return component.type.replaceAll(/_/g, '-')
}
</script>
